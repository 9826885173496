const managerUrl = "/api"; // 认证层接口地址 正式环境

const api = {
  public: {
    getArea: `${managerUrl}/system/area/children`, // 获取地区列表
  },
  system: {
    loginCode: `${managerUrl}/mgr/user/login/yzm`, // 验证码登录
    getCode: `${managerUrl}/mgr/user/login/getyzm`, // 获取验证码
    loginPwd: `${managerUrl}/mgr/user/login/pwd`, // 密码登录
    loginOut: `${managerUrl}/manager/user/login/out`, // 退出登录
    getRoles: `${managerUrl}/mgr/user/menus`, //获取权限列表
    userInfo: `${managerUrl}/mgr/user/info`, //用户信息
    updateUserAvatar: `${managerUrl}/mgr/user/upava`, //修改用户头像
    updateUserPwd: `${managerUrl}/mgr/user/uppwd`, // 修改用户密码
    areaGcj02: `${managerUrl}/system/area/gcj02`, // 地区定位接口
    oss: {
      pmmUserAvatarImg: `${managerUrl}/system/oss/policy?business=yyaUserAvatarImg`, // 用户头像
      pmmUserIdentityImg: `${managerUrl}/system/oss/policy?business=yyaUserIdentityImg`, // 身份证
      pmmUserCertImg: `${managerUrl}/system/oss/policy?business=yyaUserCertImg`, // 资质证书
      pmmUserVideoImg: `${managerUrl}/system/oss/policy?business=yyaUserVideoImg`, // 视频封面
      pmmUserVideoVideo: `${managerUrl}/system/oss/policy?business=yyaUserVideoVideo`, // 用户视频
      pmmUserWorkImg: `${managerUrl}/system/oss/policy?business=yyaUserWorkImg`, // 工作照
      pmmShopLicenseImg: `${managerUrl}/system/oss/policy?business=yyaShopLicenseImg`, // 工作照
      pmmStoreAvatarImg: `${managerUrl}/system/oss/policy?business=yyaStoreAvatarImg`, // 门店头像
      pmmStorePicImg: `${managerUrl}/system/oss/policy?business=yyaStorePicImg`, // 门店照片
      pmmStoreVideoVideo: `${managerUrl}/system/oss/policy?business=yyaStoreVideoVideo`, // 门店视频
      pmmSystemBannerImg: `${managerUrl}/system/oss/policy?business=yyaSystemBannerImg`, // 系统横幅
      pmmMallGoodsImg: `${managerUrl}/system/oss/policy?business=yyaMallGoodsImg`, // 商城商品图片
      pmmMallGoodsVideo: `${managerUrl}/system/oss/policy?business=yyaMallGoodsVideo`, // 商城商品视频
      pmmMenstruationImg: `${managerUrl}/system/oss/policy?business=yyaMenstruationImg`, // 商城商品视频
    },
  },

  // 平台管理
  platform: {
    bannerPlaces: `${managerUrl}/system/banner/places`, // 横幅位置
    bannerList: `${managerUrl}/system/banner/mgrlist`, // 横幅列表
    bannerMgr: `${managerUrl}/system/banner/mgr`, // 横幅启用禁用
    bannerAdd: `${managerUrl}/system/banner/add`, // 横幅新增
    bannerUpdate: `${managerUrl}/system/banner/update`, // 横幅修改
    wordsList: `${managerUrl}/search/words/mgrlist`, // 热搜词列表
    wordsMgr: `${managerUrl}/search/words/mgr`, // 热搜词启用禁用
    wordsAdd: `${managerUrl}/search/words/add`, // 热搜词新增
    wordsUpdate: `${managerUrl}/search/words/update`, // 热搜词修改
    problemMgrlist: `${managerUrl}/system/problem/mgrlist`, // 常见问题列表
    problemAdd: `${managerUrl}/system/problem/add`, // 常见问题新增
    problemDetail: `${managerUrl}/system/problem/detail`, // 常见问题详情
    problemUpdate: `${managerUrl}/system/problem/update`, // 常见问题修改
    problemDelete: `${managerUrl}/system/problem/delete`, // 常见问题删除
    problemMgr: `${managerUrl}/system/problem/mgr`, // 常见问题状态切换
  },
  // 门店管理
  store: {
    infoMgrList: `${managerUrl}/store/info/mgrlist`, // 运营端门店列表
    infoResetpw: `${managerUrl}/store/info/resetpw`, // 重置密码
    infoAdd: `${managerUrl}/store/info/add`, // 门店新增入驻
    infoUpdate: `${managerUrl}/store/info/update`, // 门店修改
    infoMgrDetail: `${managerUrl}/store/info/mgrdetail`, // 门店详情
    staffMgrlist: `${managerUrl}/store/staff/mgrlist`, // 门店员工列表
    staffMgrdetail: `${managerUrl}/store/staff/mgrdetail`, // 门店员工详情
    staffAdd: `${managerUrl}/store/staff/add`, // 门店员工新增
    staffUpdate: `${managerUrl}/store/staff/update`, // 门店员工修改
    staffLevelList: `${managerUrl}/store/staff/level/list`, // 员工等级
    staffLevelAdd: `${managerUrl}/store/staff/level/add`, // 员工等级新增
    staffLevelUpdate: `${managerUrl}/store/staff/level/update`, // 员工等级修改
    staffLevelMgr: `${managerUrl}/store/staff/level/mgr`, // 员工等级状态切换
    infoLabel: `${managerUrl}/store/info/label`, // 员工列表所属门店列表
  },

  // 月经知识
  learning: {
    mensMgrList: `${managerUrl}/menstruation/info/mgrlist`, // 月经常识列表
    mensDel: `${managerUrl}/menstruation/info/del`, // 月经常识删除
    mensAdd: `${managerUrl}/menstruation/info/add`, // 月经常识新增
    mensUpdate: `${managerUrl}/menstruation/info/update`, // 月经常识修改
    typeMgrList: `${managerUrl}/exam/type/mgrlist`, // 试卷类型列表
    typeAdd: `${managerUrl}/exam/type/add`, // 试卷类型添加
    typeDel: `${managerUrl}/exam/type/del`, // 试卷类型删除
    typeUpdate: `${managerUrl}/exam/type/update`, // 试卷类型修改
    typeLabel: `${managerUrl}/exam/type/label`, // 试卷类型下拉框
    infoMgrList: `${managerUrl}/exam/info/mgrlist`, // 试卷列表
    infoAdd: `${managerUrl}/exam/info/add`, // 试卷添加
    infoMgrDetail: `${managerUrl}/exam/info/mgrdetail`, // 试卷详情
    infoUpdate: `${managerUrl}/exam/info/update`, // 试卷修改
    infoLabel: `${managerUrl}/exam/info/label`, // 试卷下拉框
    topicMgrList: `${managerUrl}/exam/topic/mgrlist`, // 题目列表
    topicAdd: `${managerUrl}/exam/topic/add`, // 题目新增
    topicUpdate: `${managerUrl}/exam/topic/update`, // 题目修改
    topicMgrDetail: `${managerUrl}/exam/topic/mgrdetail`, // 题目详情
    resultMgrList: `${managerUrl}/exam/result/mgrlist`, // 结果建议列表
    resultAdd: `${managerUrl}/exam/result/add`, // 结果建议新增
    resultUpdate: `${managerUrl}/exam/result/update`, // 结果建议修改
    resultMgrDetail: `${managerUrl}/exam/result/mgrdetail`, // 结果建议详情
  },

  // 会员管理
  member: {
    userInfoMgrList: `${managerUrl}/user/info/mgrlist`, // 会员信息列表
    userAddressMgrList: `${managerUrl}/user/address/mgrlist`, // 会员地址列表
    accountInfoMgrlist: `${managerUrl}/account/info/mgrlist`, // 账户信息列表
    accountInfoMgr: `${managerUrl}/account/info/mgr`, // 账户信息冻结、解冻操作
    accountInfoMgrdetail: `${managerUrl}/account/info/mgrdetail`, // 账户明细列表
    userStatisticsMgrList: `${managerUrl}/user/statistics/mgrlist`, // 会员统计列表
    userStatisticsOverview: `${managerUrl}/user/statistics/overview`, // 会员统计数据概览
    userStatisticsTrend: `${managerUrl}/user/statistics/trend`, // 会员统计趋势
    accountStatisticsMgrList: `${managerUrl}/account/statistics/mgrlist`, // 资金统计列表
    accountStatisticsOverview: `${managerUrl}/account/statistics/overview`, // 资金统计概览
    accountStatisticsPie: `${managerUrl}/account/statistics/pie`, // 资金统计概览
    userStatisticsFromlist: `${managerUrl}/user/statistics/fromlist`, // 用户来源统计列表
    userStatisticsFromList: `${managerUrl}/user/statistics/from/list`, // 用户来源类型列表
    userStatisticsFromOverview: `${managerUrl}/user/statistics/from/overview`, // 用户来源概览
    userStatisticsFromPie: `${managerUrl}/user/statistics/from/pie`, // 用户来源饼状图
    userStatisticsPie: `${managerUrl}/user/statistics/pie`, // 用户统计饼状图
    userStatisticsBehaviorPie: `${managerUrl}/user/statistics/behavior/pie`, // 用户行为统计饼状图
    userStatisticsBehaviorDetail: `${managerUrl}/user/statistics/behavior/detail`, // 用户行为详情
    userStatisticsBehaviorOverview: `${managerUrl}/user/statistics/behavior/overview`, // 用户行为概览
    userStatisticsPie: `${managerUrl}/user/statistics/pie`, // 用户统计饼状图
    userStatisticsBehaviorlist: `${managerUrl}/user/statistics/behaviorlist`, // 用户行为统计列表
    accountStatisticsTrend: `${managerUrl}/account/statistics/trend`, // 资金统计趋势
    storeStatisticsOverview: `${managerUrl}/store/statistics/overview`, // 门店统计概览
    storeStatisticsTrend: `${managerUrl}/store/statistics/trend`, // 门店统计趋势
  },
  // 耗材
  consume: {
    // 运营端
    consumeInfoTypes: `${managerUrl}/consume/info/types`, // 耗材信息分类
    consumeInfoList: `${managerUrl}/consume/info/list`, // 耗材信息列表
    consumeInfoAdd: `${managerUrl}/consume/info/add`, // 耗材信息新增
    consumeInfoUpdate: `${managerUrl}/consume/info/update`, // 耗材信息修改
    consumeInfoDetail: `${managerUrl}/consume/info/detail`, // 耗材信息详情
    consumestoreList: `${managerUrl}/consume/store/list`, // 门店耗材列表
    consumestoreDetail: `${managerUrl}/consume/store/detail`, // 门店耗材详情
    consumestoreStatis: `${managerUrl}/consume/store/statis`, // 门店耗材统计
    consumestoreTrend: `${managerUrl}/consume/store/trend`, // 门店耗材趋势
    consumestoreMachineInfos: `${managerUrl}/consume/store/machine/infos`, // 门店仪器列表
    consumestoreMachineStatis: `${managerUrl}/consume/store/machine/statis`, // 门店仪器统计
    consumestoreMachineTrend: `${managerUrl}/consume/store/machine/trend`, // 门店仪器趋势
    consumeOrderList: `${managerUrl}/consume/order/list`, // 耗材订单列表
    consumeOrderDetail: `${managerUrl}/consume/order/detail`, // 耗材订单详情
    consumeOrderDelivery: `${managerUrl}/consume/order/delivery`, // 耗材订单发货
    consumeOrderExamine: `${managerUrl}/consume/order/examine`, // 耗材订单审核
    consumeLossesList: `${managerUrl}/consume/losses/list`, // 耗材报损列表
    consumeLossesDetail: `${managerUrl}/consume/losses/detail`, // 耗材报损列表
    consumeLossesExamine: `${managerUrl}/consume/losses/examine`, // 耗材报损审核
    consumeOverhaulList: `${managerUrl}/consume/overhaul/list`, // 仪器维修列表
    consumeOverhaulDetail: `${managerUrl}/consume/overhaul/detail`, // 仪器维修详情
    consumeOverhaulExamine: `${managerUrl}/consume/overhaul/examine`, // 仪器报修处理
    consumeOverhaulDelivery: `${managerUrl}/consume/overhaul/delivery`, // 仪器维修发货
    consumeOverhaulOk: `${managerUrl}/consume/overhaul/ok`, // 仪器维修完成
    consumeOverhaulReach: `${managerUrl}/consume/overhaul/reach`, // 仪器维修收货
    // 运营端 end

    // 门店端
    consumeStoreInfoList: `${managerUrl}/consume/store/info/list`, // 门店耗材列表
    consumeStoreLossesApply: `${managerUrl}/consume/store/losses/apply`, // 门店耗材报损
    consumeStoreInfoDetail: `${managerUrl}/consume/store/info/detail`, // 门店耗材详情
    consumeStoreMachineList: `${managerUrl}/consume/store/machine/list`, // 门店仪器列表
    consumeStoreOverhaulApply: `${managerUrl}/consume/store/overhaul/apply`, // 门店仪器维修申请
    consumeStoreMachineDetail: `${managerUrl}/consume/store/machine/detail`, // 门店仪器详情
    consumeStoreOrderList: `${managerUrl}/consume/store/order/list`, // 门店耗材订单列表
    consumeStoreOrderCancel: `${managerUrl}/consume/store/order/cancel`, // 门店耗材订单取消
    consumeStoreOrderReach: `${managerUrl}/consume/store/order/reach`, // 门店耗材订单收货
    consumeStoreOrderDetail: `${managerUrl}/consume/store/order/detail`, // 门店耗材订单详情
    consumeInfoSelect: `${managerUrl}/consume/info/select`, // 耗材信息下拉
    consumeOrderApply: `${managerUrl}/consume/order/apply`, // 门店耗材申请
    consumeStoreLossesList: `${managerUrl}/consume/store/losses/list`, // 门店报损列表
    consumeStoreLossesCancel: `${managerUrl}/consume/store/losses/cancel`, // 门店报损取消
    consumeStoreLossesDetail: `${managerUrl}/consume/store/losses/detail`, // 门店报损详情
    consumeStoreOverhaulList: `${managerUrl}/consume/store/overhaul/list`, // 门店仪器维修列表
    consumeStoreOverhaulCancel: `${managerUrl}/consume/store/overhaul/cancel`, // 门店仪器维修取消
    consumeOverhaulContact: `${managerUrl}/consume/overhaul/contact`, // 仪器维修平台邮寄信息
    consumeStoreOverhaulDelivery: `${managerUrl}/consume/store/overhaul/delivery`, // 门店仪器维修发货
    consumeStoreOverhaulReach: `${managerUrl}/consume/store/overhaul/reach`, // 门店仪器维修收货
    // 门店端 end
  },
  // 物流管理
  logistics: {
    logisticsList: `${managerUrl}/system/logistics/mgrlist`, // 物流公司列表
    logisticsAdd: `${managerUrl}/system/logistics/add`, // 物流公司新增
    logisticsDelete: `${managerUrl}/system/logistics/delete`, // 物流公司新增
    logisticsUpdate: `${managerUrl}/system/logistics/update`, // 物流公司修改
    logisticsLabel: `${managerUrl}/system/logistics/label`, // 物流公司下拉列表
  },
};

let oldApi = {
  store: {
    bossUnlist: `${managerUrl}/store/boss/unlist`, // 老板入驻待审核列表
    bossExamine: `${managerUrl}/store/boss/examine`, // 老板入驻待审核
    bossInfo: `${managerUrl}/store/boss/info`, // 老板入驻信息
    bossInfoUpdate: `${managerUrl}/store/boss/update`, // 老板入驻信息修改
    bossList: `${managerUrl}/store/boss/list`, // 老板列表
    bossAdd: `${managerUrl}/store/boss/add`, // 老板入驻新增
    bossMgr: `${managerUrl}/store/boss/mgr`, // 老板启用禁用
    baseUnlist: `${managerUrl}/store/base/unlist`, // 门店入驻待审核列表
    baseInfo: `${managerUrl}/store/base/query`, // 门店查询
    baseExamine: `${managerUrl}/store/base/examine`, // 门店入驻审核
    baseUpdate: `${managerUrl}/store/base/update`, // 门店修改
    infoUnlist: `${managerUrl}/store/info/unlist`, // 门店资料待审核
    infoUnintro: `${managerUrl}/store/info/unintro`, // 门店资料待审核详情
    infoExamine: `${managerUrl}/store/info/examine`, // 门店信息审核
    infoUnupdate: `${managerUrl}/store/info/unupdate`, // 门店信息审核
    storeExamine: `${managerUrl}/store/info/mgr`, // 门店启用禁用
    bossItem: `${managerUrl}/store/boss/items`, // 门店老板下拉信息
    baseList: `${managerUrl}/store/base/list`, // 门店老板详情门店列表
    baseAdd: `${managerUrl}/store/base/add`, // 门店入驻新增
    infoUpdate: `${managerUrl}/store/info/update`, // 门店信息修改
    infoQuery: `${managerUrl}/store/info/query`, // 门店信息详情
    infoAdd: `${managerUrl}/store/info/add`, // 门店信息详情
    storeType: `${managerUrl}/pro/type/children`, // 门店类型
    staffList: `${managerUrl}/store/staff/list`, // 门店员工
  },
  // 健康师管理
  health: {
    joinList: `${managerUrl}/tech/join/unlist`, // 健康师入驻待审核列表
    joinExamine: `${managerUrl}/tech/join/examine`, // 健康师入驻审核
    joinInfo: `${managerUrl}/tech/join/info`, // 健康师入驻审核信息
    healthType: `${managerUrl}/tech/types`, // 健康师类型
    joinUpdate: `${managerUrl}/tech/join/update`, // 健康师入驻信息修改
    intelList: `${managerUrl}/tech/list`, // 健康师信息管理列表
    intelEnable: `${managerUrl}/user/info/techmgr`, // 健康师信息管理启用禁用
    intelAddJoin: `${managerUrl}/tech/join/add`, // 健康师信息管理新增入驻
    aptitudeList: `${managerUrl}/tech/intro/unlist`, // 健康师资料待审核列表
    aptitudeExamine: `${managerUrl}/tech/intro/examine`, // 健康师资料审核
    aptitudeAdd: `${managerUrl}/tech/intro/add`, // 健康师资料新增
    aptitudeDetail: `${managerUrl}/tech/intro/undetail`, // 健康师未审核资料详情
    aptitudeInfo: `${managerUrl}/tech/intro/info`, // 健康师审核通过资料详情
    introUpdate: `${managerUrl}/tech/intro/update`, // 健康师资料修改
    introUnupdate: `${managerUrl}/tech/intro/unupdate`, // 健康师资料待审核修改
  },
  // 优惠券
  coupon: {
    list: `${managerUrl}/coupon/list`, // 优惠券列表
  },
  // 项目套餐
  project: {
    infoList: `${managerUrl}/pro/info/list`, // 项目套餐列表
    infoUnlist: `${managerUrl}/pro/info/unlist`, // 项目待审核列表
    infoExamine: `${managerUrl}/pro/info/examine`, // 项目审核
    projectType: `${managerUrl}/pro/type/children`, // 项目类别
    infoMgr: `${managerUrl}/pro/info/mgr`, // 项目禁用启用
    infoLast: `${managerUrl}/pro/info/last`, // 项目最新信息
    infoQuery: `${managerUrl}/pro/info/query`, // 项目信息
    infoSetMgr: `${managerUrl}/pro/info/setMgr`, // 项目禁用启用
  },
  // 资金管理
  fund: {
    refundList: `${managerUrl}/money/wallet/refund/list`, // 退款申请列表
    refundMgr: `${managerUrl}/money/wallet/refund/mgr`, // 退款通过拒绝
    walletList: `${managerUrl}/money/wallet/list`, // 钱包明细列表
    withdrawList: `${managerUrl}/account/withdraw/list`, // 提现列表
    withdrawMgr: `${managerUrl}/account/withdraw/mgr`, // 提现操作
    infoList: `${managerUrl}/account/info/list`, // 账户明细列表
  },
  //商品管理
  goods: {
    typeBlist: `${managerUrl}/goods/type/blist`, // 业务分类列表
    typeGlist: `${managerUrl}/goods/type/glist`, // 商品分类列表
    typeAdd: `${managerUrl}/goods/type/add`, // 商品分类新增
    typeDetail: `${managerUrl}/goods/type/detail`, // 商品分类详情
    typeUpdate: `${managerUrl}/goods/type/update`, // 商品分类修改
    infoList: `${managerUrl}/goods/info/list`, // 商品信息列表
    infoAdd: `${managerUrl}/goods/info/add`, // 商品信息新增
    infoQuery: `${managerUrl}/goods/info/query`, // 商品信息查询
    infoUpdate: `${managerUrl}/goods/info/update`, // 商品信息修改
    saleList: `${managerUrl}/goods/sale/list`, // 商品销售列表
    saleAdd: `${managerUrl}/goods/sale/add`, // 商品销售新增
    saleDetail: `${managerUrl}/goods/sale/detail`, // 商品销售详情
    saleUpdate: `${managerUrl}/goods/sale/update`, // 商品销售修改
    chaptersList: `${managerUrl}/goods/chapters/list`, // 课程章节列表
    chaptersAdd: `${managerUrl}/goods/chapters/add`, // 课程章节新增
    chaptersDetail: `${managerUrl}/goods/chapters/detail`, // 课程章节详情
    chaptersUpdate: `${managerUrl}/goods/chapters/update`, // 课程章节修改
    classesList: `${managerUrl}/goods/classes/list`, // 培训课程列表
    classesAdd: `${managerUrl}/goods/classes/add`, // 培训课程新增
    classesDetail: `${managerUrl}/goods/classes/detail`, // 培训课程详情
    classesUpdate: `${managerUrl}/goods/classes/update`, // 培训课程修改
    orderMgrlist: `${managerUrl}/goods/order/mgrlist`, // 商品订单搜索
    orderAllinfo: `${managerUrl}/goods/order/allinfo`, // 商品订单资料
    orderSend: `${managerUrl}/goods/order/send`, // 商品订单发货
    orderLogisticsList: `${managerUrl}/goods/order/logisticsList`, // 物流列表
    orderLogistics: `${managerUrl}/goods/order/logistics`, // 商品订单物流更新
    orderReturn: `${managerUrl}/goods/order/return`, // 商品订单退货
    orderArrival: `${managerUrl}/goods/order/arrival`, // 商品订单到货
  },
  // 订单管理
  order: {
    serverList: `${managerUrl}/order/server/list`, // 服务列表
    serverInfo: `${managerUrl}/order/server/info`, // 服务信息
  },
  // 官网
  website: {
    newsAdd: `${managerUrl}/website/news/add`, //新闻新增
    newsMgrlist: `${managerUrl}/website/news/mgrlist`, //新闻列表
    newsMgr: `${managerUrl}/website/news/mgr`, //新闻列表，是否首页和是否展示切换
    newsDetail: `${managerUrl}/website/news/detail`, //新闻详情
    newsUpdate: `${managerUrl}/website/news/update`, //新闻修改
    mgrlist: `${managerUrl}/website/join/mgrlist`, //加入我们列表
    joinMgr: `${managerUrl}/website/join/mgr`, //加入我们操作
  },
};

export default api;
